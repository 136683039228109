<template>
    <div class="height_fill_available">
        <!--<div></div>-->
        <el-row class="height_fill_available">
            <el-col :span="8" class="height_fill_available dis_flex flex_y_center flex_x_end">
                <div class="map_img">
                    <img src="@/assets/img/chahua@2x.png" alt="">
                    <div class="text_center font_16">重塑客户与企业的连接</div>
                </div>
            </el-col>
            <el-col :span="16" class="height_fill_available dis_flex flex_y_center flex_x_center">
                <div class="login_information">
                    <el-form
                            ref="ruleForm"
                            :model="ruleForm"
                            :rules="rules"
                            label-position="left"
                            label-width="0px"
                            class="demo-ruleForm login-container"
                    >
                        <div class="logo_title">
                            销售
                        </div>
                        <div class="font_weight_600 pad_15_0">
                            登录
                        </div>
                        <el-form-item prop="EnterpriseCode">
                            <el-input v-model="ruleForm.EnterpriseCode" placeholder="商户ID"></el-input>
                        </el-form-item>
                        <el-form-item prop="UserName">
                            <el-input v-model="ruleForm.UserName" placeholder="账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="Password">
                            <el-input type="password" v-model="ruleForm.Password" placeholder="密码">
                                <template slot="append">忘记密码</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="pad_15_0">
                            <el-button
                                    type="primary"
                                    style="width:100%;"
                                    @click.native.prevent="handleLogin"
                                    :loading="loading"
                            >登录
                            </el-button>
                        </el-form-item>

                        <div class="dis_flex flex_x_between">
                            <div class="search_privacy">点击查看"隐私政策"</div>
                            <div class="register">免费注册</div>
                        </div>
                    </el-form>

                </div>
                <div class="podixed_flex">
                    <div class="dis_flex flex_x_center flex_y_center">
                        <div class="pad_10"><img class="icon_img" src="@/assets/img/ios.png" alt=""> <span>iphone</span></div>
                        <div class="pad_10"><img class="icon_img" src="@/assets/img/android.png" alt=""> <span>Android</span></div>
                        <div class="pad_10">京公网安备201585588989号</div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import API from "@/api/account";
    import axios from 'axios'
    export default {
        data() {
            return {
                loading: false,
                ruleForm: {
                    EnterpriseCode: "",
                    UserName: "",
                    Password: ""
                },
                rules: {
                    EnterpriseCode: [{required: true, message: "请输入商户号ID", trigger: "blur"}],
                    UserName: [{required: true, message: "请输入账号或手机号", trigger: "blur"}],
                    Password: [{required: true, message: "请输入密码", trigger: "blur"}]
                }
            }
        },
        methods: {
            // 登录
            handleLogin() {
                var that = this;
                that.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.loading = true;
                        let para = that.ruleForm;
                        API.getlogin(para)
                            .then(function (res) {
                                if (res.stateCode == 100) {
                                    localStorage.setItem( "access-user",JSON.stringify(res.data));
                                    axios.defaults.headers.common['Authorization'] = 'Basic ' + res.data.authToken;

                                } else {
                                    that.$message.error({
                                        message: res.message,
                                        duration: 2000
                                    });
                                }
                            })
                            .finally(function () {
                                that.loading = false;
                            });
                    }
                });

            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/color.scss";

    .logo {
        padding: 15px;
    }

    .map_img {
        width: 100%;
        height: 100%;
        color: #409eff;
        background-color: #ecf0ff;
        img {
            width: 500px;
            height: 583px;
        }

    }

    .login_information {
        width: 400px;
        padding: 25px;
        border-radius: 5px;
        .logo_title {
            color: #409EFF;
            padding: 20px 0;
            font-weight: 600;
            font-size: 16px;
        }

        .el-input__inner {
            border: none !important;
            border-bottom: 1px solid #eeeeee !important;
            padding: 15px;
        }
        .search_privacy {
            color: $zl-text-color-grey;
        }
        .register {
            color: $zl-color-primary;
        }

    }
    .icon_img{
        width: 20px;
        height: 20px;
        vertical-align: bottom;
    }
    .podixed_flex{
        position: fixed;
        bottom: 10px;
    }
</style>